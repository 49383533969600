.cal > div > div > div >div > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cal:active > div > div {
    box-shadow: none;
}

.cal-day {
    border-radius: 5px;
}

.cal-day:hover {
    background: #EEEEEE;
}

.cal-day svg.isSelected {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.cal-day svg.isToday {
    filter: drop-shadow(2px 2px 1px #EC821D);
}
